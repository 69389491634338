body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 0px;
}

.t-row {
  display: flex;
}

.t-board {
  margin: 20px auto;
  padding: 10px 20px 20px 20px;
  border: 1px solid grey;
  position: relative;
}

.t-score-head {
  display: flex;
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 3px 5px;
}

.t-score-button {
  margin-left: auto;
  padding: 8px 10px;
  display: block;
}

.t-cell {
  display: inline-block;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  color: grey;
}

.t-cell-0 {
  background-color: black;
}

.t-cell-1 {
  background-color: #00d;
  border-bottom: 1px solid #00f;
  border-right: 1px solid #00f;
  border-top: 1px solid #00b;
  border-left: 1px solid #00b;
}

.t-cell-2 {
  background-color: green;
}

.t-cell-3 {
  background-color: red;
}

.t-score-label {
  font-weight: bold;
}

.t-score-label {
  font-weight: bold;
}

.t-parent {
  display: flex;
}

element.style {
  width: 44px;
  left: 0px;
}

.t-score-modal {
  position: absolute;
  background-color: #fff;
  text-align: center;
  left: 50%;
  top: 50%;
  padding: 20px;
  transform: translate3d(-50%, -50%, 0);
}

.t-score-modal-title {
  font-size: 18px;
  margin-bottom: 15px;
}

.controls {
  text-align: center;
  margin: 10px 0 0 0;
}

.controls button {
  height: 50px;
  width: 50px;
  margin: 5px 5px 0 5px;
}